import React from 'react'
import IconText from 'parts/IconText'
import Button from 'elements/button'

export default function Footer() {
    return (
        <footer className="container">
            <div className="row">
                <div className="col-auto" style={{ width: 350 }}>
                    <IconText />
                    <p className="brand-tagline">
                        We kaboom your beauty holiday instantly and memorable.
                    </p>
                </div>
                <div className="col-auto mr-5">
                    <h6 className="mt-2">For Beginner</h6>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <Button type="link" href={"/register"}>New Account</Button>
                        </li>
                        <li className="list-group-item">
                            <Button type="link" href={"/properties"}>Star Booking Room</Button>
                        </li>
                        <li className="list-group-item">
                            <Button type="link" href={"/use-payment"}>Use Payment</Button>
                        </li>
                    </ul>
                </div>
                <div className="col-2 mr-5">
                    <h6 className="mt-2">Explore Us</h6>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <Button type="link" href={"/careers"}>Our Careers</Button>
                        </li>
                        <li className="list-group-item">
                            <Button type="link" href={"/privacy"}>Privacy</Button>
                        </li>
                        <li className="list-group-item">
                            <Button type="link" href={"/terms"}>Terms & Conditions</Button>
                        </li>
                    </ul>
                </div>
                <div className="col-3">
                    <h6 className="mt-2">Connect Use</h6>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <Button isEkternal type="link" href="mailto:support@staycation.id">support@staycation.id</Button>
                        </li>
                        <li className="list-group-item">
                            <Button isEkternal type="link" href="tel:+622122081996">021 - 2208 - 1996</Button>
                        </li>
                        <li className="list-group-item">
                            <span>Staycation, Kemang, Jakarta</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col text-center copyrights">
                    Copyright 2019 • All rights reserved • Staycation
                </div>
            </div>
        </footer>
    )
}
