import React from 'react'

import propTypes from 'prop-types'
import Button from 'elements/button'

import './index.scss';


export default function Breadcrumb(props) {
    const className = ["breadcrumb", props.className];
    return (
        <nav className="breadcrumb pl-0">
            <ol className={className.join(" ")}>
                {props.data.map((item, index) => {
                    return (
                        <li key={`bredcrumb-${index}`}
                            className={`breadcrumb-item${index === props.data.length - 1 ? " active" : ""}`}
                        >
                            {index === props.data.length - 1 ? (item.pageTitle) : (
                                <Button type="link" href={item.pageHref}>
                                    {item.pageTitle}
                                </Button>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav >
    );
}

Breadcrumb.propTypes = {
    cat: propTypes.array,
    className: propTypes.string
};