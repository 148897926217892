import React from 'react'

import ImageHero from 'assets/images/img-hero.jpg'
import ImageHero_ from 'assets/images/img-hero-frame.jpg'

import Button from 'elements/button'
import formatNumber from 'utils/formatNumber'
import Fade from 'react-reveal/Fade'

export default function Hero(props) {

    function showMostPicked() {
        window.scrollTo({
            top: props.refMostPicked.current.offsetTop - 25,
            behavior: "smooth"
        });
    }

    return (
        <Fade bottom>
            <section className="container pt-4">
                <div className="row align-items-center">
                    <div className="col-auto pr-5" style={{ width: 530 }}>
                        <h1 className="font-weight-bold line-height-1 mb-3">Forget Busy Work, <br />Start Next Vacation</h1>
                        <p className="mb-4 font-weight-light text-gray-500 w-75" style={{ lineHeight: '170%' }}>We provide what you need to enjoy your holiday with family. Time to make another memorable moments.</p>
                        <Button className="btn px-5" hasShadow isPrimary onClick={showMostPicked}>
                            Show Me Now
                    </Button>

                        <div className="row" style={{ marginTop: 80 }}>
                            <div className="col-auto" style={{ marginRight: 35 }}>
                                <img src="/images/icon-traveler.svg" width="36" height="36" alt={`${props.data.travelers} Travelers`} />
                                <h6 className="mt-3">{formatNumber(props.data.travelers)}{" "} <span className="text-gray-500 font-weight-light">Travelers</span></h6>
                            </div>

                            <div className="col-auto" style={{ marginRight: 35 }}>
                                <img src="/images/icon-treasure.svg" width="36" height="36" alt={`${props.data.treasures} Treasure`} />
                                <h6 className="mt-3">{props.data.treasures} <span className="text-gray-500 font-weight-light">Treasure</span></h6>
                            </div>

                            <div className="col-auto">
                                <img src="/images/icon-cities.svg" width="36" height="36" alt={`${props.data.cities} Cities`} />
                                <h6 className="mt-3">{props.data.cities} <span className="text-gray-500 font-weight-light">Cities</span></h6>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 pl-5">
                        <div style={{ width: 520, height: 410 }}>
                            <img className="img-fluid position-absolute" src={ImageHero} alt="Room With Couches" style={{ margin: '-30px 0 0 -30px', zIndex: 1 }} />
                            <img className="img-fluid position-absolute" src={ImageHero_} alt="Room With Couches Frame" style={{ margin: '0 15px 15px 0' }} />
                        </div>
                    </div>
                </div>
            </section >
        </Fade>
    )
}
