import React from 'react'
import Button from 'elements/button'

export default function IconText() {
    return (
        <Button className="brand-text-icon" href="/" type="link">
            Stay<span className="text-gray-900">cation</span>
        </Button>
    )
}
